import { getDocument, useDocument } from "hooks/firestore/core/useDocument"
import { WithRef } from "hooks/firestore/FirestoreDocument"
import { getB2BCustomerRef } from "hooks/firestore/getRefs"
import { getCurrentUser, useCurrentUser } from "hooks/localstate/auth/useCurrentUser"
import { useShopId } from "hooks/localstate/context/useShopId"
import { B2BCustomer } from "types/firestore/b2bCustomer"

// ts-prune-ignore-next
export function useB2bCustomer(): WithRef<B2BCustomer> | undefined
export function useB2bCustomer(shopId: string, userId?: string): WithRef<B2BCustomer> | undefined
export function useB2bCustomer(shopId?: string) {
  // TypeScript function overloads should prevent conditional calling of the hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const realShopId = shopId || useShopId()
  const currentUser = useCurrentUser()
  const realUserId = currentUser?.uid

  const { document } = useDocument(getB2BCustomerRef(realShopId, realUserId))
  return document
}

// ts-prune-ignore-next
export async function getB2bCustomers(shopId: string, userId?: string) {
  const currentUser = getCurrentUser()
  const realUserId = userId || currentUser?.uid
  return await getDocument(getB2BCustomerRef(shopId, realUserId))
}
