import { useB2bCustomer } from "hooks/firestore/simple/useB2bCustomer"
import { usePrivateUser } from "hooks/firestore/simple/usePrivateUser"
import { useUrlQuery } from "hooks/localstate/url/useUrlQuery"

export function useB2BStorefront() {
  const privateUser = usePrivateUser()

  // Check if user is added as a b2b customer in the shop
  const b2bCustomer = useB2bCustomer()

  // Check if user has all data filled out
  const userHasAllData = !!privateUser?.b2b?.address && !!privateUser?.b2b?.businessName

  const [b2b] = useUrlQuery("b2b")

  return b2b !== undefined && b2b === "true" && b2bCustomer !== undefined && userHasAllData
}
