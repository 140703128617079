import { useCallback } from "react"
import { useToasts } from "react-toast-notifications"

export function useDisplayToast() {
  const { addToast } = useToasts()
  const displayToast = useCallback((message: string, type: "error" | "info" | "success" | "warning") => {
    addToast(message, {
      appearance: type,
      autoDismiss: true,
    })
  }, [])
  return displayToast
}
